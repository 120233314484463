<template>
  <div class="content">
    <div class="content__title content__title--start content__title--party">
      <router-link
        :to="{ name: 'passport' }"
        class="button button--light button--no-indent button--small"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z"
                fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z"
                fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">Сведения о недвижимом имуществе, находящимся в собственности региональной организации РСМ, аренде</h1>
      <router-link v-if="userRole === 'admin'" :to="{ name: 'passport-immovables-stat' }" class="link" type="button">
        <img src="@/assets/img/sidebar/stat.svg" alt="Stat icon">
        <span>Статистика</span>
      </router-link>
    </div>
    <div class="content__title">
      <h2 class="title title--theme">Региональная организация: {{ regionOrgName }}</h2>
    </div>
    <div class="content__title">
      <router-link :to="{ name: 'passport-immovables-create' }" class="link">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Добавить сведения о недвижимом имуществе</span>
      </router-link>
    </div>
    <div class="content__title content__title--party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="userListTable"
      class="table-default"
    >
<!--      <div slot="post" slot-scope="props" class="table-default__left">-->
<!--        {{ props.row.post }}-->
<!--      </div>-->
      <div slot="tooltip" slot-scope="props">
        <v-popover
          placement="left-start"
        >
          <button class="tooltip-target b3">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 4C10.1046 4 11 3.10457 11 2C11 0.89543 10.1046 0 9 0C7.89543 0 7 0.89543 7 2C7 3.10457 7.89543 4 9 4Z" fill="#2B93E7"/>
              <path d="M16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2C14 3.10457 14.8954 4 16 4Z" fill="#2B93E7"/>
              <path d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z" fill="#2B93E7"/>
            </svg>
          </button>
          <template slot="popover">
            <ul class="tooltip__list">
              <li class="tooltip__item">
                <img src="@/assets/img/edit-icon.svg" alt="Edit icon">
                <router-link :to="{name: 'passport-immovables-edit', params: { id: props.row.id }}" class="tooltip__btn">Редактировать</router-link>
              </li>
              <li class="tooltip__item">
                <img src="@/assets/img/trash-icon.svg" alt="Delete icon">
                <button @click="deleteItem(props.row.id)" class="tooltip__btn">Удалить</button>
              </li>
            </ul>
          </template>
        </v-popover>
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns'

export default {
  name: 'PassportImmovablesList',
  components: { dropdown },
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'status_name', 'tooltip'],
        options: {
          headings: {
            id: 'ID',
            status_name: 'Статус',
            tooltip: '',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: 20 },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
    };
  },
  computed: {
    regionOrgName() {
      return this.$store.state.organizationName
    },
    userRole() {
      return this.$store.state.userRole
    }
  },
  mounted() {
    this.fetchItems(1, this.limitSelected.name)
  },
  methods: {
    fetchItems(page = 1, limit) {
      this.$store.dispatch('passport/GET_IMMOVABLES_LIST', { page, limit })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name
      this.$refs.userListTable.setLimit(data.name)
      this.fetchItems(this.pagination.page, this.limitSelected.name)
    },
    clickPaginationCallback(page) {
      this.fetchItems(page, this.limitSelected.name)
    },
    returnStart() {
      return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1)
    },
    returnEnd() {
      return this.returnStart() + this.table.items.length - 1
    },
    deleteItem(id) {
      this.$store.dispatch('passport/DELETE_IMMOVABLES_DATA', id)
        .then(() => {
          document.body.click()
          this.fetchItems(1, this.limitSelected.name)
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Сведения удалены.'
          })
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    }
  }
};
</script>
